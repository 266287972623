import MainLayout from '@/components/Layout/MainLayout'
import NotFound from '@/components/Composition/NotFound'
import StandardMeta from '@/components/Common/Meta/Standard'

export default function Custom404() {
  return (
    <MainLayout>
      <StandardMeta title="Page not found" />
      <NotFound />
    </MainLayout>
  )
}
