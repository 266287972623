import Container from '@/components/Primitive/Container'
import Lottie from 'react-lottie'
import React from 'react'
import Type from '@/components/Primitive/Type'

import animationData from './animation.json'
import styles from './NotFound.module.scss'

const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <Container gutter size="wide" center className={styles.NotFound}>
      <Type size="title" bold>
        Oops! Page not found.
      </Type>
      <div className={styles.Animation}>
        <Lottie options={defaultOptions} isStopped={false} isPaused={false} />
      </div>
    </Container>
  )
}

NotFound.propTypes = {}

export default NotFound
